@use '@angular/material' as mat;

//Overrides for components
:root {
  h1 {
    font-size: 2rem;
    font-weight: bold;
  }

  .custom-toggle {
    transform: scale(1.2);
    margin-left: 8px;
    .mdc-form-field {
      font-size: 1rem;
    }
    .mdc-switch {
      margin-right: 8px;
    }
  }

  button {
    border-radius: var(--border-radius);

    &.button-primary {
      background-color: var(--theme-primary);
      color: white !important;
      border: none;

      &:hover {
        background-color: var(--theme-primary-light) !important;
      }
      @include mat.button-overrides(
        (
          filled-container-color: var(--theme-primary),
          filled-hover-state-layer-opacity: 0.04,
          outlined-hover-state-layer-opacity: 0,
        )
      );
    }

    &.button-secondary {
      background-color: white;
      color: black !important;

      &:hover {
        color: var(--theme-primary) !important;
      }
      @include mat.button-overrides(
        (
          filled-label-text-color: white,
          outlined-hover-state-layer-opacity: 0,
        )
      );
    }
    &.button-tertiary {
      background-color: white;
      color: black !important;

      &:hover {
        color: var(--theme-primary) !important;
      }
      @include mat.button-overrides(
        (
          filled-label-text-color: white,
          outlined-hover-state-layer-opacity: 0,
        )
      );
    }

    &:disabled {
      background-color: #e9e9e9 !important;
      color: rgba(0, 0, 0, 0.38) !important;
    }
  }

  @include mat.select-overrides(
    (
      panel-background-color: white,
    )
  );
  & {
    --mat-sys-secondary-container: var(--theme-hover);
  }
  @include mat.dialog-overrides(
    (
      container-color: white,
      subhead-color: var(--theme-regularTextColor),
    )
  );

  @include mat.card-overrides(
    (
      title-text-weight: 500,
    )
  );

  @include mat.stepper-overrides(
    (
      header-icon-foreground-color: var(--theme-primary),
      header-selected-state-icon-background-color: var(--theme-primary),
      header-icon-background-color: var(--theme-default),
      header-error-state-icon-foreground-color: white,
    )
  );

  @include mat.tabs-overrides(
    (
      active-label-text-color: var(--theme-primary),
      active-hover-label-text-color: var(--theme-primary),
      active-hover-indicator-color: var(--theme-primary),
      active-focus-label-text-color: var(--theme-primary),
    )
  );
}
