@use '@angular/material' as mat;
@use './styles/themes/_theme-colors' as theme-colors;
@use './styles/components';
@include mat.elevation-classes();
@include mat.app-background();

:root {
  --theme-primary: #e2382a;
  --theme-primary-light: #ff334b;
  --theme-accent: #08acd3;
  --theme-regularTextColor: #424345;
  --theme-background: #e4edf0;
  --theme-success: #527a00;
  --theme-orange: #ff8f00;
  --theme-yellow: #ffc900;
  --theme-toDo: #fffbeb;
  --theme-info: #08acd3;
  --theme-default: #e0e0e0;
  --theme-border: #e0e0e0;
  --theme-hover: #e0e0e0;
  --theme-disabled: #e9e9e9;

  --theme-inactive-row-bg: rgba(0, 0, 0, 0.05);
  --theme-inactive-row-hover-bg: rgba(0, 0, 0, 0.1);
  --theme-info-bg: rgba(240, 240, 240, 0.2);
  --border-radius: 4px;
  --mat-sys-shape-medium: var(--border-radius);
  --mat-sys-corner-medium: var(--border-radius);
  --mat-sys-primary: var(--theme-primary);
  --mobileWidth: 768px;
  // --mat-sys-on-surface-variant: #e4edf0;
}

html {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  @include mat.theme(
    (
      color: (
        primary: theme-colors.$primary-palette,
        tertiary: theme-colors.$tertiary-palette,
      ),
      typography: (
        plain-family: Roboto,
        // bold-weight: 900,
        // medium-weight: 500,
        // regular-weight: 300,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
      ),
      density: 0,
    )
  );
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: var(--theme-regularTextColor);
  // background-color: var(--theme-background) !important;
}

// Media queries and other styles remain the same

:root {
  --screenWidth: 25rem;
}

@media only screen and (max-width: 768px) {
  :root {
    --screenWidth: 20rem;
  }
}

@media only screen and (max-width: 450px) {
  :root {
    --screenWidth: 100%;
  }
}
